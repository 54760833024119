import React from "react";
import { Footer, Navbar } from "../components";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <section className="py-5">
        <div className="w-10/12 mx-auto mb-5 pb-5">
          <div className="text-center">
            <h1
              className="font-48 fw-bold "
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              Privacy Policy
            </h1>
            <p
              className="font-18 fw-medium"
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              This Privacy Policy applies to all personal information collected
              by TIME LINK TECH PTY LTD & Registered business names via the
              website located at www.timelink.au & associated domain names.
            </p>
          </div>
          <div className="inter mt-lg-5 mt-md-5 mt-3">
            <h2
              className="font-30 fw-semibold"
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              1. What is "personal information"?
            </h2>
            <p
              className="font-20 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.5)", marginBottom: "2rem" }}
            >
              (a) The Privacy Act 1988 (Cth) currently defines "personal
              information" as meaning information or an opinion about an
              identified individual or an individual who is reasonably
              identifiable:
              <br />
              <span style={{ marginLeft: "2rem" }}>
                (i) whether the information or opinion is true or not; and
                <br />
              </span>
              <span style={{ marginLeft: "2rem" }}>
                (ii) whether the information or opinion is recorded in a
                material form or not.
              </span>
              <br />
              (b) If the information does not disclose your identity or enable
              your identity to be ascertained, it will in most cases not be
              classified as "personal information" and will not be subject to
              this privacy policy.
            </p>

            <h2
              className="font-30 fw-semibold"
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              2. What information do we collect?
            </h2>
            <p
              className="font-20 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.5)", marginBottom: "2rem" }}
            >
              The kind of personal information that we collect from you will
              depend on how you use the website. The personal information which
              we collect and hold about you may include:
              <br />
              (i) Collection of Personal and business Information: TimeLink
              collects and processes personal information from users and
              business clients to provide and enhance our services. The types of
              information we collect are as follows:
              <br />
              <span>
                (ii) Identification Information: We collect names, email
                addresses, and phone numbers of our users and business
                representatives for account creation and communication purposes.
                <br />
                (iii) Business Information: For our business clients, we gather
                details such as business name, physical address, business
                category, and website URL to customise our service offerings and
                ensure relevance.
                <br />
                (iv) Demographic Data: Age, gender, and geographical location of
                users are collected to enhance service personalisation and
                deliver targeted offerings.
                <br />
                (v) Payment Details: TimeLink processes payment information,
                including credit card details and billing addresses, for
                managing subscriptions and other financial transactions.
                <br />
                (vi) User Interaction Data: We monitor and record how users
                interact with our platform, including their order history,
                preferences, feedback, and responses to surveys, to continually
                improve user experience.
                <br />
                (vii) Device Information: Technical data such as IP addresses
                and browser types are collected for operational and security
                purposes and to optimise the platform’s performance across
                different devices.
                <br />
                (viii) Communication Records: Any interactions with our customer
                service or communications made through our platform, like emails
                and chat logs, are recorded for quality assurance and service
                improvement.
                <br />
                (ix) User-Generated Content: Content created or uploaded by
                users, including custom responses in surveys and marketing
                materials, is collected to provide relevant services.
                <br />
                (x) Loyalty Program Details: Information related to
                participation in any loyalty programs, such as usage and
                redemption history, is used to manage these programs
                effectively.
                <br />
                (xi) Social Media Data: If users link their social media
                accounts, we may collect pertinent information from these
                platforms to enrich their TimeLink experience.
              </span>
              <br />
              The collection of this information allows TimeLink to tailor its
              services to meet the specific preferences and requirements of our
              users and business clients. By using our platform, you consent to
              the collection, use, and sharing of this information in accordance
              with our Privacy Policy.
            </p>

            <h2
              className="font-30 fw-semibold"
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              3. How we collect your personal information
            </h2>
            <p
              className="font-20 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.5)", marginBottom: "2rem" }}
            >
              (a) We may collect personal information from you whenever you
              input such information into the website.
              <br />
              (b) We also collect cookies from your computer which enable us to
              tell when you use the website and also to help customise your
              website experience. As a general rule, however, it is not possible
              to identify you personally from our use of cookies.
            </p>

            <h2
              className="font-30 fw-semibold"
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              4. Purpose of collection
            </h2>
            <p
              className="font-20 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.5)", marginBottom: "2rem" }}
            >
              (a) The purpose for which we collect personal information is to
              provide you with the best service experience possible on the
              website.
              <br />
              (b) We customarily disclose personal information only to our
              service providers who assist us in operating the website. Your
              personal information may also be exposed from time to time to
              maintenance and support personnel acting in the normal course of
              their duties.
              <br />
              (c) By using our website, you consent to the receipt of direct
              marketing material. We will only use your personal information for
              this purpose if we have collected such information directly from
              you, and if it is material of a type which you would reasonably
              expect to receive from us. We do not use sensitive personal
              information in direct marketing activity. Our direct marketing
              material will include a simple means by which you can request not
              to receive further communications of this nature.
            </p>

            <h2
              className="font-30 fw-semibold"
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              5. Access and correction
            </h2>
            <p
              className="font-20 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.5)", marginBottom: "2rem" }}
            >
              Australian Privacy Principle 12 permits you to obtain access to
              the personal information we hold about you in certain
              circumstances, and Australian Privacy Principle 13 allows you to
              correct inaccurate personal information subject to certain
              exceptions. If you would like to obtain such access, please
              contact us as set out below.
            </p>

            <h2
              className="font-30 fw-semibold"
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              6. Complaint procedure
            </h2>
            <p
              className="font-20 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.5)", marginBottom: "2rem" }}
            >
              If you have a complaint concerning the manner in which we maintain
              the privacy of your personal information, please contact us as set
              out below. All complaints will be considered by Time Link's CEO at
              the time of complaint and we may seek further information from you
              to clarify your concerns. If we agree that your complaint is well
              founded, we will, in consultation with you, take appropriate steps
              to rectify the problem. If you remain dissatisfied with the
              outcome, you may refer the matter to the Office of the Australian
              Information Commissioner.
            </p>

            <h2
              className="font-30 fw-semibold"
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              7. Overseas transfer
            </h2>
            <p
              className="font-20 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.5)", marginBottom: "2rem" }}
            >
              Your personal information may be transferred overseas or stored
              overseas for a variety of reasons. It is not possible to identify
              each and every country to which your personal information may be
              sent. If your personal information is sent to a recipient in a
              country with data protection laws which are at least substantially
              similar to the Australian Privacy Principles, and where there are
              mechanisms available to you to enforce protection of your personal
              information under that overseas law, we will not be liable for a
              breach of the Australian Privacy Principles if your personal
              information is mishandled in that jurisdiction. If your personal
              information is transferred to a jurisdiction which does not have
              data protection laws as comprehensive as Australia's, we will take
              reasonable steps to secure a contractual commitment from the
              recipient to handle your information in accordance with the
              Australian Privacy Principles.
            </p>

            <h2
              className="font-30 fw-semibold"
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              8. How to contact us about privacy
            </h2>
            <p
              className="font-20 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.5)" }}
            >
              If you have any queries, or if you seek access to your personal
              information, or if you have a complaint about our privacy
              practices, you can contact us through: support@timelink.au.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
