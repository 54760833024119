export const faqsData = [
  {
    id: "panel1",
    question: "What is TimeLink and how does it work? ",
    answer:
      "TimeLink is a digital order notification system designed for small-to-medium-sized businesses. It works by generating a unique QR code and NFC link for each order, allowing customers to track their order status in real-time through their mobile devices.",
  },
  {
    id: "panel2",
    question: "How do I integrate TimeLink with my Square POS system?",
    answer:
      "To integrate TimeLink with Square POS, log into your TimeLink admin panel, locate the 'Integrations' section, and click 'Connect' under the Square option. This will allow your Square orders to automatically sync with TimeLink, generating tracking codes for your customers.",
  },
  {
    id: "panel3",
    question: "What types of businesses can benefit from using TimeLink?",
    answer:
      "TimeLink is ideal for cafes, pizza shops, burger shops, and other small-to-medium-sized take-away businesses. It helps streamline order management and enhances customer satisfaction through real-time order tracking.",
  },
  {
    id: "panel4",
    question: "Do I need any other Hardware?",
    answer:
      "Yes, you will need a tablet to run the TimeLink software. You can use any Android tablet that meets our system specifications. If you don’t already have a suitable tablet, you can purchase one directly from us, either on a monthly plan or through an outright purchase. We also provide the essential TimeLink Connect device as part of your subscription, ensuring you have all the necessary tools to get started.",
  },
  {
    id: "panel5",
    question: "Are there any hidden fees or additional costs? ",
    answer:
      "No, TimeLink does not have any hidden fees. All costs are clearly outlined in our pricing plans. Any additional features or devices will be transparently billed based on your selected plan. We will never charge you for any service without your say so.",
  },
  {
    id: "panel6",
    question: "Can I cancel my subscription at any time? ",
    answer:
      "We strive to offer flexibility with our services, including the ability to cancel your subscription at any time. To initiate a cancellation, please contact our support team. We will assist you with the process and provide instructions for returning any TimeLink hardware you have received. Your subscription will be paused until the hardware is returned in good condition. This approach helps ensure that the process is clear and managed effectively, maintaining a positive relationship with customers even as they exit.",
  },
  {
    id: "panel7",
    question: "Can I upgrade or downgrade my plan at any time? ",
    answer:
      "Yes, you can adjust your subscription plan to best fit your needs at any time. To upgrade or downgrade your plan, please contact our support team. They will guide you through the process and ensure that your transition between plans is smooth and hassle-free.",
  },
  {
    id: "panel8",
    question: "How can I contact the TimeLink team? ",
    answer:
      "You can contact the TimeLink team by visiting our website and navigating to the ‘Contact Us’ section. Here, you will find options to either fill out a contact form, send us an email directly, or use our chat support for immediate assistance. Our team is dedicated to providing timely and helpful responses to all inquiries.",
  },
  // Add more FAQ items as needed
];
