import React from "react";
import { Navbar, Footer } from "../components";

const CookiesPolicy = () => {
  return (
    <>
      <Navbar />
      <section className=" py-5 ">
        <div className="w-10/12 mx-auto termsCondition-page mb-5 pb-5">
          <div className="text-center">
            <h1
              className="font-48 fw-bold "
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              Cookies Policy
            </h1>
            {/* <p className="font-18 fw-medium" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                            Id sagittis bibendum tortor praesent. Sit viverra.
                        </p> */}
          </div>
          <div className="inter mt-lg-5 mt-md-5 mt-3">
            <p className="font-20 fw-medium mb-2">Last updated: 25 Nov 2023</p>
            <p className="font-20 fw-medium mb-2">
              This Website (referred to in these “terms of use” as the website)
              is owned and operated by TIME LINK TECH PTY LTD, who is referred
              to in this Cookie Policy as “we”, “us”, “our” and similar
              grammatical forms.
            </p>
            <p className="font-20 fw-medium mb-2">
              Our Cookie Policy explains what cookies are, how we use cookies,
              how third-party partners may use cookies on our Websites and your
              choices regarding cookies.{" "}
            </p>
            <p className="font-20 fw-medium mb-2">
              General information about visits to our Websites is collected by
              our computer servers, with small files “cookies” that our Websites
              transfers to your computer’s hard drive through your Web browser
              (if you allow the delivery of “cookies”). The “cookies” are used
              to follow the pattern of movements of users by letting us know
              which pages on our Websites are visited, in what order and how
              often and the previous website visited and also to process the
              items you select if you are making purchases from our Websites.
              The anonymous non-personal information that we collect and analyse
              is not personal information as described in the Privacy Act.
            </p>
            <div>
              <br />
              <div className="d-flex align-items-start">
                <h1
                  className="font-30 fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  1.&nbsp;
                </h1>
                <h1
                  className="font-30 fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Why do we use “cookies” and other web use tracking
                  technologies?
                </h1>
              </div>
              <ol type="a">
                <li className="font-20 fw-medium ">
                  When you access our Website, small files containing a unique
                  identification (ID) number may be downloaded by your web
                  browser and stored in the cache of your computer. The purpose
                  of sending these files with a unique ID number is so that our
                  Website can recognise your computer when you next visit our
                  Website. The “cookies” that are shared with your computer
                  can’t be used to discover any personal information such as
                  your name, address or email address they merely identify your
                  computer to our Websites when you visit us.
                </li>
                <li className="font-20 fw-medium ">
                  We can also log the internet protocol address (IP address) of
                  visitors to our Website so that we can work out the countries
                  in which the computers are located.
                </li>
                <li className="font-20 fw-medium ">
                  We collect information using “cookies” and other tracking
                  technologies for the following reasons:
                  <ol type="i">
                    <li className="font-20 fw-medium ">
                      to help us monitor the performance of our Website so that
                      we can improve the operation of the Website and the
                      services we offer;
                    </li>
                    <li className="font-20 fw-medium ">
                      to provide personalised services to each user of our
                      Website to make their navigation through our Website
                      easier and more rewarding to the user;
                    </li>
                    <li className="font-20 fw-medium ">
                      to sell advertising on the Website in order to meet some
                      of the costs of operating the Website and improve the
                      content on the Website; and
                    </li>
                    <li className="font-20 fw-medium ">
                      when we have permission from the user, to market the
                      services we provide by sending emails that are
                      personalised to what we understand are the interests of
                      the user.
                    </li>
                  </ol>
                </li>
                <li className="font-20 fw-medium ">
                  Even if you have given us permission to send you emails, you
                  can, at any time, decide not to receive further emails and
                  will be able to “unsubscribe” from that service. (e) In
                  addition to our own cookies, we may also use various
                  third-parties cookies to report usage statistics of the
                  Website, deliver advertisements on and through the Website,
                  and so on.
                </li>
              </ol>
              <div className="d-flex align-items-start">
                <h1
                  className="font-30 fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  2.&nbsp;
                </h1>
                <h1
                  className="font-30 fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  What are your choices regarding cookies?
                </h1>
              </div>
              <p className="font-20 fw-medium mb-2">
                When you access our Website, small files containing a unique
                identification (ID) number may be downloaded by your web browser
                and stored in the cache of your computer. The purpose of sending
                these files with a unique ID number is so that our Website can
                recognise your computer when you next visit our Website. The
                “cookies” that are shared with your computer can’t be used to
                discover any personal information such as your name, address or
                email address they merely identify your computer to our Websites
                when you visit us.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CookiesPolicy;
