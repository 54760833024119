export const startPlanas = [
  {
    name: "Order Notification System",
    status: "enabled",
  },
  {
    name: "Business Console",
    status: "enabled",
  },
  {
    name: "750 SMS notifications",
    status: "enabled",
  },
  {
    name: "Web-Based Order Tracking",
    status: "enabled",
  },
  {
    name: "WhatsApp Messaging Notifications",
    status: "disabled",
  },
  {
    name: "Business Tablet Application",
    status: "disabled",
  },
  {
    name: "Square POS Integration",
    status: "enabled",
  },
  {
    name: "TimeLink Dashboard",
    status: "disabled",
  },
  {
    name: "Basic Analytics",
    status: "enabled",
  },
  {
    name: "Daily Stats",
    status: "enabled",
  },
  {
    name: "Busiest Periods",
    status: "enabled",
  },
  {
    name: "Daily Orders",
    status: "enabled",
  },
  {
    name: "Average wait-time",
    status: "enabled",
  },
  {
    name: "Time Link Connect Device ($74.99 Value)",
    status: "enabled",
  },
  {
    name: "Support",
    status: "enabled",
  },
  {
    name: "Help Centre",
    status: "disabled",
  },
];
export const proPlans = [
  {
    name: "Order Notification Feature",
    status: "enabled",
  },
  {
    name: "Business Console",
    status: "enabled",
  },
  {
    name: "1,250 SMS notifications",
    status: "enabled",
  },
  {
    name: "Web-Based Notifications",
    status: "enabled",
  },
  {
    name: "WhatsApp Messaging Notification",
    status: "disabled",
  },
  {
    name: "Business Tablet Application",
    status: "disabled",
  },
  {
    name: "Square POS Integration",
    status: "enabled",
  },
  {
    name: "TimeLink Dashboard",
    status: "disabled",
  },
  {
    name: "Comprehensive Analytics",
    status: "disabled",
  },
  {
    name: "Daily Stats",
    status: "enabled",
  },
  {
    name: "Busiest Periods",
    status: "enabled",
  },
  {
    name: "Daily Orders",
    status: "enabled",
  },
  {
    name: "Average wait-time",
    status: "enabled",
  },
  {
    name: "Time Link Connect Device ($74.99 Value)",
    status: "enabled",
  },
  {
    name: "Priority Support",
    status: "enabled",
  },
  {
    name: "Help Centre",
    status: "disabled",
  },
  {
    name: "Marketing Campaign",
    status: "enabled",
  },
  {
    name: "1 FREE Accumulating Marketing Credits EACH month ($75 Value)",
    status: "enabled",
  },
  {
    name: "Customer Demographics",
    status: "enabled",
  },
  {
    name: "Age Group",
    status: "enabled",
  },
  {
    name: "Repeat Customer Rate",
    status: "enabled",
  },
  {
    name: "Frequency of Visits",
    status: "enabled",
  },
  {
    name: "Clicks",
    status: "enabled",
  },
  {
    name: "Impressions",
    status: "enabled",
  },
  {
    name: "Reach",
    status: "enabled",
  },
  {
    name: "Click-Through-Rate",
    status: "enabled",
  },
];
export const elitePlans = [
  {
    name: "Order Notification Feature",
    status: "enabled",
  },
  {
    name: "Business Console",
    status: "enabled",
  },
  {
    name: "1,750 SMS notifications",
    status: "enabled",
  },
  {
    name: "Web-Based Notifications",
    status: "enabled",
  },
  {
    name: "WhatsApp Messaging Notification",
    status: "enabled",
  },
  {
    name: "Business Tablet Application",
    status: "disabled",
  },
  {
    name: "Square POS Integration",
    status: "disabled",
  },
  {
    name: "TimeLink Dashboard",
    status: "disabled",
  },
  {
    name: "Comprehensive Analytics",
    status: "enabled",
  },
  {
    name: "Daily Stats",
    status: "disabled",
  },
  {
    name: "Busiest Periods",
    status: "disabled",
  },
  {
    name: "Daily Orders",
    status: "disabled",
  },
  {
    name: "Average wait-time",
    status: "disabled",
  },
  {
    name: "Time Link Connect Device ($74.99 Value)",
    status: "enabled",
  },
  {
    name: "Priority Support",
    status: "disabled",
  },
  {
    name: "Help Centre",
    status: "disabled",
  },
  {
    name: "Marketing Campaign",
    status: "enabled",
  },
  {
    name: "2 FREE Accumulating Marketing Credits EACH month ($150 Value)",
    status: "enabled",
  },
  {
    name: "Customer Demographics",
    status: "enabled",
  },
  {
    name: "Age Group",
    status: "enabled",
  },
  {
    name: "Repeat Customer Rate",
    status: "enabled",
  },
  {
    name: "Frequency of Visits",
    status: "enabled",
  },
  {
    name: "Clicks",
    status: "enabled",
  },
  {
    name: "Impressions",
    status: "enabled",
  },
  {
    name: "Reach",
    status: "enabled",
  },
  {
    name: "Click-Through-Rate",
    status: "enabled",
  },
  {
    name: "Survey Program",
    status: "disabled",
  },
  {
    name: "Request Google Review",
    status: "disabled",
  },
  {
    name: "Request Feedback",
    status: "disabled",
  },
];
