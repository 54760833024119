import { Navbar, Footer } from "../components";

const TermCondition = () => {
  return (
    <>
      <Navbar />
      <section className="termsCondition-page py-5 ">
        <div className="w-10/12 mx-auto mb-5 pb-5">
          <div className="text-center">
            <h1
              className="font-48 fw-bold "
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              Terms & Conditions of Use
            </h1>
            {/* <p className="font-18 fw-medium" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                            Id sagittis bibendum tortor praesent. Sit viverra.
                        </p> */}
          </div>
          <div className="inter mt-lg-5 mt-md-5 mt-3">
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                1.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                About the Website{" "}
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                Welcome to www.timelink.au <strong>(Website)</strong>. The
                Website allows you to access and use the Time Link{" "}
                <strong>(Services)</strong>.
              </li>
              <li className="font-20 fw-medium ">
                The Website is operated by TIME LINK TECH PTY LTD, ACN/ABN 661
                854 244 . Access to and use of the Website, or any of its
                associated products or Services, is provided by TIME LINK TECH
                PTY LTD. Please read these terms and conditions{" "}
                <strong>(Terms)</strong> carefully. By using, browsing and/or
                reading the Website, this signifies that you have read,
                understood and agree to be bound by the Terms. If you do not
                agree with the Terms, you must cease usage of the Website, or
                any of Services, immediately.
              </li>
              <li className="font-20 fw-medium ">
                TIME LINK TECH PTY LTD reserves the right to review and change
                any of the Terms by updating this page at its sole discretion.
                When TIME LINK TECH PTY LTD updates the Terms, it will use
                reasonable endeavours to provide you with notice of updates to
                the Terms. Any changes to the Terms take immediate effect from
                the date of their publication. Before you continue, we recommend
                you keep a copy of the Terms for your records.
              </li>
            </ol>

            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                2.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Acceptance of the Terms
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                You accept the Terms by remaining on the Website. Where the
                option is available in the user interface, you may also accept
                the Terms by clicking to accept or agree to the Terms.
              </li>
            </ol>

            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                3.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                About the Service
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                Time Link is TimeLink offers a digital order notification system
                that integrates with a business's existing tablet and web
                applications, allowing businesses to track orders and engage
                with customers through features like order updates, surveys, and
                loyalty programs..
              </li>
              <li className="font-20 fw-medium ">
                Essential, Growth and Elite Plan accounts are currently offered.
                You acknowledge and agree that the accounts offered, as well as
                the account features may change from time to time, and may be
                governed by separate terms which apply specific to the account.
                Where special account-specific terms apply, you will be
                informed, and must accept those terms before you are given such
                an account. For the avoidance of doubt these Terms apply unless
                otherwise agreed or amended by account-specific terms.
              </li>
              <li className="font-20 fw-medium ">
                Some accounts may be governed by a separate Software Licensing
                Agreement with TIME LINK TECH PTY LTD, which may amend the terms
                of use. For the avoidance of doubt these Terms apply unless
                otherwise agreed or amended by the terms of an applicable
                Software Licensing Agreement.
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                4.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Acceptable use of the Service
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                Time Link, its related features, and website must only be used
                lawfully. TIME LINK TECH PTY LTD reserves the right to suspend,
                cancel, or otherwise deny access to users and accounts who use
                the service:
              </li>
              <ol type="i">
                <li className="font-20 fw-medium ">
                  To engage in any act that would disrupt the access,
                  availability, and security of Time Link and other TIME LINK
                  TECH PTY LTD services, including but not limited to:
                </li>
                <ol type="A">
                  <li className="font-20 fw-medium ">
                    Tampering with, reverse-engineering, or hacking our servers.
                  </li>
                  <li className="font-20 fw-medium ">
                    Modifying, disabling, or compromising the performance Time
                    Link or other TIME LINK TECH PTY LTD services.
                  </li>
                  <li className="font-20 fw-medium ">
                    Overwhelming, or attempting to overwhelm our infrastructure
                    by imposing an unreasonably large load on our systems that
                    consume extraordinary resources.
                  </li>
                  <li className="font-20 fw-medium ">
                    Compromising the integrity of our system, including probing,
                    scanning and testing the vulnerability of our system unless
                    expressly permitted by TIME LINK TECH PTY LTD.
                  </li>
                </ol>
              </ol>
              <li className="font-20 fw-medium ">
                For any illegal purpose, or to violate any laws, including and
                without limitation to data, privacy, and export control laws.
              </li>
              <li className="font-20 fw-medium ">
                To stalk, harass or threaten users and any member of the public.
              </li>
              <li className="font-20 fw-medium ">
                To misrepresent or defraud any user or member of the public
                through phishing, spoofing, manipulating headers or other
                identifiers, impersonating anyone else, or falsely implying any
                sponsorship or association with TIME LINK TECH PTY LTD or any
                third party (e) To access or search any part of the Service, or
                any other Service owned by TIME LINK TECH PTY LTD other than our
                publicly supported interface, or otherwise allowed for in an
                applicable Software Licensing Agreement.
              </li>
              <li className="font-20 fw-medium ">
                To post, upload, share, or otherwise circulate content in
                violation of Time Link’s content policy{" "}
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                5.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Security and Data Privacy
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                TIME LINK TECH PTY LTD takes your privacy seriously and
                information provided through your use of the Website and/or
                Services are subject to TIME LINK TECH PTY LTD’s Privacy Policy,
                which is available on the Website. The Privacy Policy also
                addresses TIME LINK TECH PTY LTD’s processes, policies, and
                obligations in respect of Time Link security breaches.
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                6.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Data Use
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                TIME LINK TECH PTY LTD collects, stores, and processes your data
                on Time Link. The data is used to provide Services to you, as
                well as to facilitate TIME LINK TECH PTY LTD’s business
                operations. The Privacy Policy outlined how your data is
                collected, stored, and processed by TIME LINK TECH PTY LTD. The
                Privacy Policy also addresses TIME LINK TECH PTY LTD's
                processes, policies, and obligations in respect of data
                encryption and removal requests.
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                7.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Subscription to use the Service
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                In order to access the Services, you must first purchase a
                subscription through the Website <strong>(Subscription)</strong>{" "}
                and pay the applicable fee for the selected Subscription{" "}
                <strong>(Subscription Fee)</strong>. After purchasing a
                Subscription, you will be considered a member (‘Member’).
              </li>
              <li className="font-20 fw-medium ">
                In purchasing the Subscription, you acknowledge and agree that
                it is your responsibility to ensure that the Subscription you
                elect to purchase is suitable for your use. (c) Before, during
                or after you have purchased the Subscription, you will then be
                required to register for an account through the Website before
                you can access the Services <strong>(Account)</strong>.
              </li>
              <li className="font-20 fw-medium ">
                As part of the registration process, or as part of your
                continued use of the Services, you may be required to provide
                personal information about yourself (such as identification or
                contact details), including but not limited to (i) Collection of
                Personal and business Information TimeLink collects and
                processes personal information from users and business clients
                to provide and enhance our services. The types of information we
                collect are as follows: (ii) Identification Information: We
                collect names, email addresses, and phone numbers of our users
                and business representatives for account creation and
                communication purposes. (iii) Business Information: For our
                business clients, we gather details such as business name,
                physical address, business category, and website URL to
                customise our service offerings and ensure relevance. (iv)
                Demographic Data: Age, gender, and geographical location of
                users are collected to enhance service personalisation and
                deliver targeted offerings. (v) Payment Details: TimeLink
                processes payment information, including credit card details and
                billing addresses, for managing subscriptions and other
                financial transactions. User Interaction Data: We monitor and
                record how users interact with our platform, including their
                order history, preferences, feedback, and responses to surveys,
                to continually improve user experience. (vi) Device Information:
                Technical data such as IP addresses and browser types are
                collected for operational and security purposes and to optimise
                the platform’s performance across different devices. (vii)
                Communication Records: Any interactions with our customer
                service or communications made through our platform, like emails
                and chat logs, are recorded for quality assurance and service
                improvement. (viii) User-Generated Content: Content created or
                uploaded by users, including custom responses in surveys and
                marketing materials, is collected to provide relevant services.
                (ix) Loyalty Program Details: Information related to
                participation in any loyalty programs, such as usage and
                redemption history, is used to manage these programs
                effectively. (x) Social Media Data: If users link their social
                media accounts, we may collect pertinent information from these
                platforms to enrich their TimeLink experience. The collection of
                this information allows TimeLink to tailor its services to meet
                the specific preferences and requirements of our users and
                business clients. By using our platform, you consent to the
                collection, use, and sharing of this information in accordance
                with our Privacy Policy..
              </li>
              <li className="font-20 fw-medium ">
                You warrant that any information you give to TIME LINK TECH PTY
                LTD in the course of completing the registration process will
                always be accurate, correct and up to date. (f) Once you have
                completed the registration process, you will be a registered
                user of the Website and agree to be bound by the Terms ("User").
                As a Member you will be granted immediate access to the Services
                from the time you have completed the registration process until
                the subscription period expires{" "}
                <strong>(Subscription Period)</strong>.
              </li>
              <li className="font-20 fw-medium ">
                You may not use the Services and may not accept the Terms if:
                <ol type="i">
                  <li className="font-20 fw-medium ">
                    you are not of legal age to form a binding contract with
                    TIME LINK TECH PTY LTD
                  </li>

                  <li className="font-20 fw-medium ">
                    Or you are a person barred from receiving the Services under
                    the laws of Australia or other countries including the
                    country in which you are resident or from which you use the
                    Services
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                8.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Payments
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                Subject to the terms of any applicable Software License
                Agreement, the Subscription Fee may be paid by all payment
                methods available on the Website, and may change from time to
                time.
              </li>
              <li className="font-20 fw-medium ">
                Payments made in the course of your use of Time Link may be made
                using third-party applications and services not owned, operated,
                or otherwise controlled by TIME LINK TECH PTY LTD. You
                acknowledge and agree that TIME LINK TECH PTY LTD will not be
                liable for any losses or damage arising from the operations of
                third-party payment applications and services. You further
                acknowledge and warrant that you have read, understood and agree
                to be bound by the terms and conditions of the applicable
                third-party payment applications and services you choose to use
                as a payment method for Time Link services.
              </li>
              <li className="font-20 fw-medium ">
                You acknowledge and agree that where a request for the payment
                of the Subscription Fee is returned or denied, for whatever
                reason, by your financial institution or is unpaid by you for
                any other reason, then you are liable for any costs, including
                banking fees and charges, associated with the Subscription Fee
              </li>
              <li className="font-20 fw-medium ">
                You agree and acknowledge that TIME LINK TECH PTY LTD can vary
                the Subscription Fee at any time and that the varied
                Subscription Fee will come into effect following the conclusion
                of the existing Subscription.
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                9.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Refund Policy
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                TIME LINK TECH PTY LTD offers refunds in accordance with the
                Australian Consumer Law and on the terms set out in these terms
                and conditions. Any benefits set out in these terms and
                conditions may apply in addition to consumer's rights under the{" "}
                <em>Australian Consumer Law</em>
              </li>
              <li className="font-20 fw-medium ">
                TIME LINK TECH PTY LTD will only provide you with a refund of
                the Subscription Fee in the event they are unable to continue to
                provide the Services or if the manager of TIME LINK TECH PTY LTD
                makes a decision, at its absolute discretion, that it is
                reasonable to do so under the circumstances. Where this occurs,
                the refund will be in the proportional amount of the
                Subscription Fee that remains unused by the Member{" "}
                <strong>(Refund)</strong>
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                10.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Copyright and Intellectual Property
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                The Website, the Services and all of the related products of
                TIME LINK TECH PTY LTD are subject to copyright. The material on
                the Website is protected by copyright under the laws of
                Australia and through international treaties. Unless otherwise
                indicated, all rights (including copyright) in the Services and
                compilation of the Website (including but not limited to text,
                graphics, logos, button icons, video images, audio clips,
                Website code, scripts, design elements and interactive features)
                or the Services are owned or controlled for these purposes, and
                are reserved by TIME LINK TECH PTY LTD or its contributors. (b)
                All trademarks, service marks and trade names are owned,
                registered and/or licensed by TIME LINK TECH PTY LTD, who grants
                to you a worldwide, non-exclusive, royalty-free, revocable
                license whilst you are a User to:
                <ol type="i">
                  <li className="font-20 fw-medium ">
                    use the Website pursuant to the Terms;
                  </li>
                  <li className="font-20 fw-medium ">
                    copy and store the Website and the material contained in the
                    Website in your device's cache memory; and
                  </li>
                  <li className="font-20 fw-medium ">
                    copy and store the Website and the material contained in the
                    Website in your device's cache memory; and
                  </li>
                </ol>
              </li>
              <li className="font-20 fw-medium ">
                TIME LINK TECH PTY LTD does not grant you any other rights
                whatsoever in relation to the Website or the Services. All other
                rights are expressly reserved by TIME LINK TECH PTY LTD.
              </li>
              <li className="font-20 fw-medium ">
                TIME LINK TECH PTY LTD retains all rights, title and interest in
                and to the Website and all related Services. Nothing you do on
                or in relation to the Website will transfer any:
                <ol type="i">
                  <li className="font-20 fw-medium ">
                    business name, trading name, domain name, trade mark,
                    industrial design, patent, registered design or copyright,
                    or
                  </li>
                  <li className="font-20 fw-medium ">
                    a right to use or exploit a business name, trading name,
                    domain name, trade mark or industrial design, or
                  </li>
                  <li className="font-20 fw-medium ">
                    a thing, system or process that is the subject of a patent,
                    registered design or copyright (or an adaptation or
                    modification of such a thing, system or process), to you.
                  </li>
                </ol>
              </li>
              <li className="font-20 fw-medium ">
                You may not, without the prior written permission of TIME LINK
                TECH PTY LTD and the permission of any other relevant rights
                owners: broadcast, republish, up-load to a third party,
                transmit, post, distribute, show or play in public, adapt or
                change in any way the Services or third party Services for any
                purpose, unless otherwise provided by these Terms. This
                prohibition does not extend to materials on the Website, which
                are freely available for re-use or are in the public domain.
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                11.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                General Disclaimer
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                Nothing in the Terms limits or excludes any guarantees,
                warranties, representations or conditions implied or imposed by
                law, including the Australian Consumer Law (or any liability
                under them) which by law may not be limited or excluded.
              </li>
              <li className="font-20 fw-medium ">
                Subject to this clause, and to the extent permitted by law:
                <ol type="i">
                  <li className="font-20 fw-medium ">
                    All terms, guarantees, warranties, representations or
                    conditions which are not expressly stated in the Terms are
                    excluded; and
                  </li>
                  <li className="font-20 fw-medium ">
                    TIME LINK TECH PTY LTD will not be liable for any special,
                    indirect or consequential loss or damage (unless such loss
                    or damage is reasonably foreseeable resulting from our
                    failure to meet an applicable Consumer Guarantee), loss of
                    profit or opportunity, or damage to goodwill arising out of
                    or in connection with the Services or these Terms (including
                    as a result of not being able to use the Services or the
                    late supply of the Services), whether at common law, under
                    contract, tort (including negligence), in equity, pursuant
                    to statute or otherwise.
                  </li>
                </ol>
              </li>
              <li className="font-20 fw-medium ">
                Use of the Website and the Services is at your own risk.
                Everything on the Website and the Services is provided to you
                "as is" and "as available" without warranty or condition of any
                kind. None of the affiliates, directors, officers, employees,
                agents, contributors and licensors of TIME LINK TECH PTY LTD
                make any express or implied representation or warranty about the
                Services or any products or Services (including the products or
                Services of TIME LINK TECH PTY LTD) referred to on the Website.
                This includes (but is not restricted to) loss or damage you
                might suffer as a result of any of the following:
                <ol type="i">
                  <li className="font-20 fw-medium ">
                    Failure of performance, error, omission, interruption,
                    deletion, defect, failure to correct defects, delay in
                    operation or transmission, computer virus or other harmful
                    component, loss of data, communication line failure,
                    unlawful third party conduct, or theft, destruction,
                    alteration or unauthorised access to records;
                  </li>
                  <li className="font-20 fw-medium ">
                    The accuracy, suitability or currency of any information on
                    the Website, the Services, or any of its Services related
                    products (including third party material and advertisements
                    on the Website);
                  </li>
                  <li className="font-20 fw-medium ">
                    Costs incurred as a result of you using the Website, the
                    Services or any of the products of TIME LINK TECH PTY LTD;
                    and
                  </li>
                  <li className="font-20 fw-medium ">
                    The Services or operation in respect to links which are
                    provided for your convenience.
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                12.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Limitation of Liability
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                TIME LINK TECH PTY LTD's total liability arising out of or in
                connection with the Services or these Terms, however arising,
                including under contract, tort (including negligence), in
                equity, under statute or otherwise, will not exceed the resupply
                of the Services to you. (b) You expressly understand and agree
                that TIME LINK TECH PTY LTD, its affiliates, employees, agents,
                contributors and licensors shall not be liable to you for any
                direct, indirect, incidental, special consequential or exemplary
                damages which may be incurred by you, however caused and under
                any theory of liability. This shall include, but is not limited
                to, any loss of profit (whether incurred directly or
                indirectly), any loss of goodwill or business reputation and any
                other intangible loss.
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                13.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Termination of Contract
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                The Terms will continue to apply until terminated by either you
                or by TIME LINK TECH PTY LTD as set out below.
              </li>
              <li className="font-20 fw-medium ">
                If you want to terminate the Terms, you may do so by:
                <ol type="i">
                  <li className="font-20 fw-medium ">
                    Not renewing the Subscription prior to the end of the
                    Subscription Period;
                  </li>
                  <li className="font-20 fw-medium ">
                    Providing TIME LINK TECH PTY LTD with 7 days’ notice of your
                    intention to terminate; and
                  </li>
                  <li className="font-20 fw-medium ">
                    Closing your accounts for all of the services which you use,
                    where TIME LINK TECH PTY LTD has made this option available
                    to you.
                  </li>
                </ol>
              </li>
              <li className="font-20 fw-medium ">
                Any notices pursuant to Clause 13.2 above should be sent, in
                writing, to TIME LINK TECH PTY LTD via the 'Contact Us' link on
                our homepage.
              </li>
              <li className="font-20 fw-medium ">
                TIME LINK TECH PTY LTD may at any time, terminate the Terms with
                you if:{" "}
                <ol type="i">
                  <li className="font-20 fw-medium ">
                    you do not renew the Subscription at the end of the
                    Subscription Period;
                  </li>
                  <li className="font-20 fw-medium ">
                    you have breached any provision of the Terms or intend to
                    breach any provision;
                  </li>
                  <li className="font-20 fw-medium ">
                    TIME LINK TECH PTY LTD is required to do so by law;
                  </li>
                  <li className="font-20 fw-medium ">
                    the provision of the Services to you by TIME LINK TECH PTY
                    LTD is, in the opinion of TIME LINK TECH PTY LTD, no longer
                    commercially viable.{" "}
                  </li>
                </ol>
              </li>
              <li className="font-20 fw-medium ">
                Subject to local applicable laws, TIME LINK TECH PTY LTD
                reserves the right to discontinue or cancel your Subscription or
                Account at any time and may suspend or deny, in its sole
                discretion, your access to all or any portion of the Website or
                the Services without notice if you breach any provision of the
                Terms or any applicable law or if your conduct impacts TIME LINK
                TECH PTY LTD's name or reputation or violates the rights of
                those of another party.
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                14.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Indemnity
              </h1>
            </div>
            <ol type="a">
              <li className="font-20 fw-medium ">
                You agree to indemnify TIME LINK TECH PTY LTD, its affiliates,
                employees, agents, contributors, third party content providers
                and licensors from and against:
                <ol type="i">
                  <li className="font-20 fw-medium ">
                    all actions, suits, claims, demands, liabilities, costs,
                    expenses, loss and damage (including legal fees on a full
                    indemnity basis) incurred, suffered or arising out of or in
                    connection with your content;
                  </li>
                  <li className="font-20 fw-medium ">
                    any direct or indirect consequences of you accessing, using
                    or transacting on the Website or attempts to do so; and/or
                  </li>
                  <li className="font-20 fw-medium ">
                    any breach of the Terms
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                15.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Dispute Resolution
              </h1>
            </div>
            <div className="ps-3 mt-2">
              <div className="d-flex align-items-start">
                <h4
                  className=" fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  15.1&nbsp;
                </h4>
                <h4
                  className=" fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Compulsory:
                </h4>
              </div>
              <p className="font-20 fw-medium mb-2">
                If a dispute arises out of or relates to the Terms, either party
                may not commence any Tribunal or Court proceedings in relation
                to the dispute, unless the following clauses have been complied
                with (except where urgent interlocutory relief is sought).
              </p>
              <div className="d-flex align-items-start">
                <h4
                  className=" fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  15.2&nbsp;
                </h4>
                <h4
                  className=" fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Notice:
                </h4>
              </div>
              <p className="font-20 fw-medium mb-2">
                A party to the Terms claiming a dispute{" "}
                <strong>(Dispute)</strong> has arisen under the Terms, must give
                written notice to the other party detailing the nature of the
                dispute, the desired outcome and the action required to settle
                the Dispute.
              </p>
              <div className="d-flex align-items-start">
                <h4
                  className=" fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  15.3&nbsp;
                </h4>
                <h4
                  className=" fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Resolution:
                </h4>
              </div>
              <p className="font-20 fw-medium mb-2">
                On receipt of that notice <strong>(Notice)</strong> by that
                other party, the parties to the Terms <strong>(Parties)</strong>{" "}
                must:
              </p>
              <ol type="a">
                <li className="font-20 fw-medium ">
                  Within 28 days of the Notice endeavour in good faith to
                  resolve the Dispute expeditiously by negotiation or such other
                  means upon which they may mutually agree;
                </li>
                <li className="font-20 fw-medium ">
                  If for any reason whatsoever, 28 days after the date of the
                  Notice, the Dispute has not been resolved, the Parties must
                  either agree upon selection of a mediator or request that an
                  appropriate mediator be appointed by the Australian Mediation
                  Association;
                </li>
                <li className="font-20 fw-medium ">
                  The Parties are equally liable for the fees and reasonable
                  expenses of a mediator and the cost of the venue of the
                  mediation and without limiting the foregoing undertake to pay
                  any amounts requested by the mediator as a precondition to the
                  mediation commencing. The Parties must each pay their own
                  costs associated with the mediation;
                </li>
                <li className="font-20 fw-medium ">
                  The mediation will be held in Melbourne, Australia.
                </li>
              </ol>
              <div className="d-flex align-items-start">
                <h4
                  className=" fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  15.4&nbsp;
                </h4>
                <h4
                  className=" fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Confidential:
                </h4>
              </div>
              <p className="font-20 fw-medium mb-2">
                All communications concerning negotiations made by the Parties
                arising out of and in connection with this dispute resolution
                clause are confidential and to the extent possible, must be
                treated as "without prejudice" negotiations for the purpose of
                applicable laws of evidence.
              </p>
              <div className="d-flex align-items-start">
                <h4
                  className=" fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  15.5&nbsp;
                </h4>
                <h4
                  className=" fw-semibold "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Termination of Mediation:
                </h4>
              </div>
              <p className="font-20 fw-medium mb-2">
                If 2 months have elapsed after the start of a mediation of the
                Dispute and the Dispute has not been resolved, either Party may
                ask the mediator to terminate the mediation and the mediator
                must do so.
              </p>
            </div>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                16.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Venue and Jurisdiction
              </h1>
            </div>

            <p className="font-20 fw-medium ">
              The Services offered by TIME LINK TECH PTY LTD is intended to be
              viewed by residents of Australia. In the event of any dispute
              arising out of or in relation to the Website, you agree that the
              exclusive venue for resolving any dispute shall be in the courts
              of Victoria.
            </p>

            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                17.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Governing Law
              </h1>
            </div>

            <p className="font-20 fw-medium ">
              The Terms are governed by the laws of Victoria. Any dispute,
              controversy, proceeding or claim of whatever nature arising out of
              or in any way relating to the Terms and the rights created hereby
              shall be governed, interpreted and construed by, under and
              pursuant to the laws of Victoria, Australia without reference to
              conflict of law principles, notwithstanding mandatory rules. The
              validity of this governing law clause is not contested. The Terms
              shall be binding to the benefit of the parties hereto and their
              successors and assigns.
            </p>
            <br />
            <div className="d-flex align-items-start">
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                18.&nbsp;
              </h1>
              <h1
                className="font-30 fw-semibold "
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Severance
              </h1>
            </div>

            <p className="font-20 fw-medium ">
              If any part of these Terms is found to be void or unenforceable by
              a Court of competent jurisdiction, that part shall be severed and
              the rest of the Terms shall remain in force.
            </p>
            {/* <br />
                        <h1 className="font-30 fw-semibold " style={{ color: 'rgba(0, 0, 0, 1)' }}>

                        </h1>
                        <ol type="a">
                            <li className="font-20 fw-medium " >

                            </li>
                        </ol> */}
          </div>
        </div>
      </section>
      <Footer />
      Copy code
      <style>
        {`  ol li {
    margin: 12px 0px ;
  }
`}
      </style>
    </>
  );
};

export default TermCondition;
