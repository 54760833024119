import React, { ReactNode } from "react";

interface CustomCardProps {
  border?: string;
  shadow?: string;
  bg?: string;
  rounded?: string;
  padding?: string;
  addClass?: string;
  imgClass?: string;
  img?: ReactNode;
  heading?: string;
  headingClass?: string;
  descriptionClass?: string;
  description?: string;
}

const CustomCard: React.FC<CustomCardProps> = ({
  border,
  shadow,
  bg,
  rounded,
  padding,
  addClass,
  imgClass,
  img,
  heading,
  headingClass,
  descriptionClass,
  description,
}) => {
  return (
    <div
      className={`${rounded ? rounded : "rounded-3xl"} ${
        shadow ? shadow : "shadow-[0_16px_65.1px_0_#0C0C0D12]"
      } ${bg ? bg : "bg-white"} ${
        border ? border : "border border-[#E2F2FE]"
      } ${padding ? padding : "p-8 py-12"} ${addClass}`}
    >
      {img && (
        <div
          className={`w-20 h-20 flex justify-center items-center bg-[#F4F6FF] rounded-full ${imgClass}`}
        >
          {img}
        </div>
      )}
      <h5 className={`text-lg font-bold my-3 ${headingClass}`}>
        {heading && heading}
      </h5>
      <p className={`text-[#212529] ${descriptionClass}`}>
        {description && description}
      </p>
    </div>
  );
};

export default CustomCard;
