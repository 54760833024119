import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const token = sessionStorage.getItem("access-token");
  return (
    <>
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
